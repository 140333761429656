@import 'styles/variables.scss';

.logonWrapper-text {
  & label {
    margin-bottom: 0.5rem;
    display: inline-block;
    span{
      color: $color_red_primary;
    }
  }
}

.form-input {
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #4f4f4f;
  background-color: $color-white-primary;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  appearance: none;
  cursor: text;
  border-radius: 0.25rem;
}

// input:-webkit-autofill {
//   border: 3px solid blue;
//   background-color: transparent;
// }
// input:autofill {
//   border: 3px solid blue;
//   background-color: transparent;
// }
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// /* Firefox */
// input[type='number'] {
//   -moz-appearance: textfield;
// }
.f-right{
  float: right;
}
