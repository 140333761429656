@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.bg-light {
  // background-image: url('../../../assets/images/DivumBackground.jpg') !important;
  background-repeat: no-repeat !important;
  background-size: cover;
}

.loginWrapper-Image {
  border-radius: 50%;
  width: 9rem;
  height: 9rem;
}

.logonWrapper-text {
  text-align: left;
  // margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.loginWrapper-valid-text {
  color: $color_red_primary;
  // font-size: 1rem;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}

.loginWrapper-status-text {
  color: $color_red_primary;
  font-size: 1rem;
  margin: 1rem;
  text-align: center;
}

.data {
  @include absCenter;
  padding: 1rem;
}

.form-control:focus {
  color: $color-blue-primary;
  background-color: $color-white-primary;
  border-color: $color-primary !important;
  outline: 0;
  box-shadow: 0 0 0 0.1rem $color-primary !important;
}

.password {
  text-align: right;
  cursor: pointer;
}
