.cipla-editor {
  margin-top: 1.5rem;

  & label {
    margin-bottom: 1rem;
    .asterik{
      color: #ff0000;
      font-weight: 400;
    }
  }
}

.rdw-editor-main {
  height: 10rem !important;
  border: 1px solid #f1f1f1;
  margin-bottom: 5px;
  margin-top: -0.6rem !important;
}
textarea {
  width: 100% !important;
  margin-top: -0.6rem !important;
  border: 1px solid #f1f1f1;
  // height: 2rem;
  background-color: white;
}

// .wrapper-class {
//   // padding: 1rem;
//   // border: 1px solid #ccc;
// }
.editor-class {
  background-color: white;
  padding: 0.5rem;
  border: 1px solid #ccc;
  // border: none;
  padding-top: 1rem;
}

.toolbar-class {
  padding: 0.5rem;
  border: 1px solid #ccc;
  // border: none;
}
