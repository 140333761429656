@import 'styles/variables.scss';

.header {
  width: 100%;
  background-color: $color-white-primary;
  // position: fixed;
  // position: sticky;
  // top: 0;
  // z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: $headerbar_ht;

  &__logo {
    height: 2rem;
  }

  &__logout {
    height: 1.4rem;
    object-fit: cover;
    cursor: pointer;
  }
}
