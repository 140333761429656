.pnf {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  margin: auto;
  height: 100vh;
  text-align: center;

  & h1 {
    font-size: 3rem;
    margin: 0;
    margin-bottom: 1rem;
    letter-spacing: 0.1rem;
  }

  & h2 {
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 1rem;
    letter-spacing: 0.1rem;
  }

  & p {
    font-size: 1.2rem;
    margin: 0;
    margin-bottom: 1rem;
    letter-spacing: 0.1rem;
  }
}
