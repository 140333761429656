.course-section {
  margin-top: 1rem;
  background-color: #f8f8f8;
  border: 1px dashed #d1d1d1;
  padding: 0.7rem;
  margin-bottom: 0.5rem;
  &_title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #d1d1d1;
    padding-bottom: 0.7rem;
    margin-bottom: 0.7rem;
    h4 {
      margin: 0;
      font-weight: 500;

      &.heading-fade{
        opacity: 0.3;
      }
    }
  }
}
.form-wrapper {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  .form-field {
    width: 48%;
  }
  &.quarter-field {
    .form-field {
      width: 16%;
      label {
        display: block;
        margin-bottom: 10px;
      }
      &:first-child {
        width: 40%;
      }
      &:nth-child(2) {
        width: 20%;
      }
    }
  }
}
.divider-hr {
  margin: 2rem 0;
  border-top: 1px dashed #d1d1d1;
  opacity: 1;
}
.multiselect-btn {
  display: flex;
  flex-flow: wrap;
  gap: 8px;
  list-style: none;
  padding: 0;
  font-size: 0.8rem;
  margin-top: 10px;
  li {
    border: 1px solid #147ddf;
    padding: 14px 16px;
    color: #147ddf;
    border-radius: 4px;
    cursor: pointer;
    // &.active,&:hover{background:#147ddf;color:#fff;}
    &.active {
      background: #147ddf;
      color: #fff;
    }
  }
}

.two-field {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #3b3f42;
}
.frontend-search {
  label {
    display: block;
    margin-bottom: 15px;
    span{
      color: #ff0000;
    }
  }
  .badge-list {
    margin-bottom: 15px;
    a {
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      line-height: 1;
      margin-left: 5px;
      position: relative;
      top: 2px;
    }
  }
  .search-wrapper {
    .chip {
      padding: 2px 5px;
      border-radius: 5px;
      color: #fff;
      font-size: 0.75rem;
      line-height: 1.3;
      background: #719720;
      text-transform: uppercase;
    }
    .searchBox {
      display: block;
      width: 100%;
      height: 3rem;
      padding: 0 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.6;
      color: #4f4f4f;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid #bdbdbd;
      appearance: none;
      cursor: text;
      border-radius: 0.25rem;
    }
  }
  .optionListContainer {
    max-width: 300px;
    left: 0.5rem;
    li {
      padding: 5px;
    }
  }

  .flex-row-reverse {
    .primary-btn {
      margin-right: 0;
    }
  }
}

.video-field-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .form-field {
    width: 30%;
  }
  .or-text {
    display: flex;
    align-items: center;
    margin-top: 3.1rem;
    padding: 1rem;
    font-weight: bold;
  }
  .duration-field {
    margin-top: 1.5rem;
    width: 18%;
    label {
      margin-bottom: 0.5rem;
      .asterisk{
        color: #ff0000;
      }
    }
  }
}
.chapter-wrap {
  .chapter-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul {
      display: flex;
      align-items: center;
      gap: 15px;
      list-style: none;
      margin: 0;
      padding: 0;
      button {
        background: #fff;
        border-radius: 15px;
        padding: 5px 16px;
        color: #147ddf;
        border: 1px solid;
        &:hover {
          background: #147ddf;
          color: #fff;
        }
      }
    }
  }
}
.link {
  text-decoration: none;
}

.duration-filed {
  .logonWrapper-text {
    margin-top: 0;
  }
}

.course-chapter-table {
  figure {
    width: 160px;
    height: 90px;
    padding: 10px;
    background: #fff;
    border: 2px solid #ccc;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}
