@import 'styles/variables.scss';

.contentWrapper {
  flex: 1 1;
  top: $headerbar_ht;
  left: $sidebar_width;
  // position: fixed;
  width: calc(100vw - $sidebar_width);
  height: calc(100vh - $headerbar_ht);
  padding: 1rem;
  overflow: scroll;
}

.custommodal {
  background: rgba($color: $black-color, $alpha: 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
}
