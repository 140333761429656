@import "styles/variables.scss";

.pagination {
    margin-right: 0 !important;
}

.table {
    > :not(caption) > * > * {
      padding: 0 !important;
    }
  
   
  }