.rating-form {
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  .field {
    display: flex;
    align-items: center;
    gap: 15px;
    label {
      font-weight: 500;
    }
  }
  .field-select {
    background: #f8f8f8;
    border: 1px solid #d1d1d1;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 10px;
    border-radius: 0.375rem;
  }
}

.form-wrapper {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  &.rating-form {
    justify-content: flex-start;
  }
  .form-field {
    width: 48%;
    .asterisk{
      color: #ff0000;
    }
  }
  &.quarter-field {
    .form-field {
      width: 16%;
        .asterisk{
          color: #ff0000;
        }
      label {
        display: block;
        margin-bottom: 10px;
      }
      &:first-child {
        width: 40%;
      }
      &:nth-child(2) {
        width: 20%;
      }
    }
  }
}

.two-field {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #3b3f42;
  label{
    span{
      &.asterisk{
        color: #ff0000;
        font-weight: 400;
      }
    }
  }
}

.create-rating-form {
  margin-top: 40px;
  .form-field {
    margin-bottom: 1.5rem;
    width: 80%;
    label{
      span{
        &.asterisk{
          color: #ff0000;
          font-weight: 400;
        }
      }
    }
  }
  .newattachment {
    padding: 0;
  }
  .logonWrapper-text {
    width: 100%;
  }
  .custom-select {
    width: 100%;
    height: 3rem;
  }
  .two-field {
    gap: 50px;
    width: 80%;
    margin-bottom: 1.5rem;
    > div {
      display: flex;
      width: 50%;
      align-items: center;
      &:first-child {
        width: 70%;
      }
      &:last-child {
        width: 30%;
        label {
          min-width: 100px;
        }
      }
    }
    label {
      min-width: 220px;
      gap: 20px;
    }
  }
  .logonWrapper-text,
  .form-field {
    display: flex;
    align-items: center;
    gap: 20px;
    label {
      width: 200px;
      flex-shrink: 0;
    }
    .logonWrapper-text {
      margin-top: 0;
    }
  }
  .toggle-with-label {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .four-field {
    display: flex;
    gap: 1rem;
    flex-flow: wrap;
    margin-top: 20px;
    > input,
    > .logonWrapper-text {
      width: calc(50% - 1rem);
      margin-top: 0;
    }
  }
  .attach-row {
    display: flex;
  }
}

.nudge-rating-form {
  .logonWrapper-text {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .field {
    position: relative;
    label {
      margin: 0 20px 0 0;
    }
    .form-input {
      width: 100%;
      max-width: 102px;
      height: 2.5rem;
      padding-right: 3.375rem;
    }

    .nudge-days {
      position: absolute;
      top: 50%;
      right: 0.75rem;
      transform: translateY(-50%);
    }
  }
}
