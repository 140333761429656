.CategoriesCard-container {
  margin-top: 1rem;
  background-color: #f8f8f8;
  &__CategoriesCard {
    width: 100%;

    height: auto;
  }

  &__section-one {
    border: 1px dashed #d1d1d1;
    padding: 0.7rem;
    margin-bottom: 0.5rem;
  }

  &__Image-Container {
    width: 100%;
    padding: 1rem;

    & .attachment {
      padding: 0;
    }
  }

  & .card__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #d1d1d1;
    padding-bottom: 0.7rem;
    margin-bottom: 0.7rem;

    // &__section {
    //   font-weight: bold;
    // }
    & p {
      margin-bottom: 0;
      font-weight: bold;
    }

    & img {
      cursor: pointer;
    }
  }
}
