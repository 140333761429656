@import 'styles/variables.scss';

.qcard {
  margin: 1rem 0;
  position: relative;

  &_sectitle {
    margin-bottom: 0.3rem;
    font-weight: 600;
    font-size: 1.1rem;
  }

  &_wrapper {
    padding: 1rem;
    border: 0.1rem solid $color-white-secondary;

    & .logonWrapper-text {
      margin-top: 0;
    }

    // & .qcard
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.7rem;

    & p {
      margin-bottom: 0;
    }

    & img {
      cursor: pointer;
    }
  }

  &__type {
    display: flex;
    margin-bottom: 1rem;

    & .radio-btn {
      margin-left: 1rem;
    }
  }

  &__datetime {
    margin-top: 1rem;
  }

  &_mcq {
    margin-top: 1rem;

    & .check {
      margin-top: 0.5rem;
    }

    &_edit {
      & > .radio-btn,
      & > .check {
        align-items: center;
        margin-top: 0.5rem;

        & > .logonWrapper-text {
          width: 60%;
        }

        & .form-input {
          height: initial;
          border: none;
          border-bottom: 1px solid #bdbdbd;

          &:focus {
            background-color: initial;
            box-shadow: none;
          }
        }
      }
    }
  }

  &_footer {
    display: flex;
  }

  // End
  &__add {
    position: absolute;
    right: -2.5rem;
    bottom: 0;

    & img {
      width: 2rem;
      cursor: pointer;
    }
  }
}
