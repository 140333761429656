// @import 'styles/variables.scss';

@import 'styles/variables.scss';

.attachment {
  display: flex;
  // flex-direction: column;
  padding: 1.5rem 2rem 0 0;
  cursor: pointer;
  position: relative;

  .asterisk{
    color: $color_red_primary;
    font-size: 1rem;
    font-weight: 400;
  }
  &_text {
    font-weight: bold;
    font-size: $fs12;
    margin-left: 0.3rem;
    margin-bottom: 0;
  }
  

  &__input-file {
    display: none;
  }

  &__selimage {
    height: 10rem;
    margin-left: 3rem;
    cursor: default;
  }

  &_img_wrapper {
    position: relative;
    // margin-top: 3rem;
    // margin-left: -10rem;
    // margin-bottom: 1rem;

    &_close {
      padding: 0.2rem;
      background-color: $color-white-primary;
      position: absolute;
      content: '';
      width: 2rem;
      height: 2rem;
      right: 0;
      top: 0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      & img {
        width: 1rem;
      }
    }
  }
}
