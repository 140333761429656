@import 'styles/variables.scss';
.Banner_size {
  width: 362px;
  height: 221px;
  opacity: 1;
  margin-left: 3rem;
  cursor: default;
}

.widget_box {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;

  &_divide {
    margin: 0 2rem 0 2rem;
    border-right: 1px dashed #d1d1d1;
  }

  &_image {
    width: 100% !important;
    // height: 168px !important;
    margin-top: 20px;
    object-fit: cover !important;
  }

  &_gif {
    // height: 280px !important;
    height: auto !important;
  }

  &_top {
    margin-top: 16px;
    margin-bottom: 1rem;
    padding: 0 16px;

    &_title {
      font-size: 16px;
    }

    &_desc {
      padding-top: 2px;
      font-size: 14px;
      color: rgba($color: $color-black-primary, $alpha: 0.54);
    }
  }

  &_cta {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  &_content {
    padding: 0 16px;
    margin-bottom: 20px;
    align-items: center;
    flex-direction: column;
  }
  &_feed__attachment {
    // width: 280px;
    // height: 144px;
    max-width: 280px;
    max-height: 100px;
    opacity: 1;
    // margin-left: 3rem;
    cursor: default;
  }
}

.widget-button-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 2rem 0;

  & .button:not(:last-child) {
    margin-right: 1rem;
  }

  &__title {
    font-size: 1.2rem;
    margin-bottom: 0;
  }

  &__right {
    display: flex;
  }
}
.isAddButtonDisabledtrue {
  padding: 20px;
  margin-bottom: 20px;
  // bottom: 10%;
  box-shadow: 10px 10px 10px 10px #888 !important;
  z-index: 999;
}
