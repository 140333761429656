.flex-field{width: 100%;
    .logonWrapper-text{display: flex;align-items: center;}
label{min-width: 200px;}
}
.text-toggle-wrap{display: flex;align-items: center;}
.text-toggle{display: flex;align-items: center;gap:20px;
span{font-size: 0.8rem;}
}
.mt2{margin-top: 2rem;}
.text-right{text-align: right;}


   .form-list{
    list-style: none;margin: 0;padding: 0;
    li{display: flex;align-items: center;gap:20px;margin-bottom: 1rem;
    label{min-width: 200px;cursor: pointer;}
    input[type="checkbox"]{margin-right: 5px;}
    .form-input{width: 100px;}
    }
    .list-title{
        strong{min-width: 200px;color: #696969;font-size: 0.8rem;}
    }
    .logonWrapper-text{margin: 0;min-width: 200px;
    & + .logonWrapper-text{min-width: auto;}
    }
   }
   .widget_box{
    .input-group-text{background: #fff;}
    .input-group-prepend + .logonWrapper-text{margin: 0;}
   }
   .rules-head{display: flex;gap:15px;align-items: center;
    h6{margin: 0;}
}
