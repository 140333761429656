.activate-tab{
  button{
      margin:0 1rem;
      background-image: linear-gradient(to left, #f1f2f4 99%, #f1f2f4 1%);
      color:#4a5975;
      border-radius: 1rem;
      height: 3rem;
  }
  .active-btn{
      background-image: linear-gradient(to left, #0057d8 99%, #0057d8 1%);
      color:#fff
  }

}
.file-preview{
  width: 4rem;
}
.file-preview-xs{
  width: 1.5rem;
}
.borderlight{
  border:1px solid #e1e1e1
}
.file-close-icon{
  width: 3rem;
  height: 3rem;
}
