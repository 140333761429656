@import 'styles/variables.scss';

.article-detail {
  overflow: scroll;
  height: 100%;
  font-size: 1rem;
  // padding: 1rem;
  // background-color: transparent;
  min-height: 3rem;

  & .page-loader {
    width: 100vw;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: normal;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  ol,
  ul {
    padding: 0 1rem;
    margin-top: 0;
    margin-bottom: 0;
    margin-block-start: 12px;
    margin-block-end: 0;
    // background-color: #fafafa;
  }

  blockquote {
    border-left: 0.2rem solid #bfd023 !important;
    margin: 0;
    margin-left: 1rem;
    padding: 0 1rem;
  }

  hr {
    opacity: 0.25;
    margin: 1rem 0;
    background-color: #cbb;
  }

  & img {
    object-fit: contain;
    width: 100vw;
    height: initial;
  }

  & iframe {
    width: 100vw;
  }

  & .nocontent {
    display: flex;
    justify-content: center;
    padding: 2rem;
  }
}

.article {
  &_video {
    margin-top: 3rem;
    height: 168px !important;
    width: 100%;
  }

  &_image {
    width: 100% !important;
    height: 168px !important;
    margin-top: 20px;
    object-fit: cover !important;
  }

  &_gif {
    // height: 280px !important;
    height: auto !important;
  }

  &_top {
    margin-top: 16px;
    margin-bottom: 1rem;
    padding: 0 16px;

    &_title {
      font-size: 16px;
    }

    &_desc {
      padding-top: 2px;
      font-size: 14px;
      color: rgba($color: $color-black-primary, $alpha: 0.54);
    }
  }

  &_cta {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  &_content {
    padding: 0 16px;
    margin-bottom: 20px;
    align-items: center;
    flex-direction: column;
  }
}
