.blogsFormfaq {
  padding: 2rem;
  width: 80%;
}
.blogsFormfaqbutton {
  padding: 2rem;
  width: 80%;
}
.faq_list {
  margin-left: 1.6rem;
  margin-right: 1.6rem;

  .faq_list_question_box {
    padding: 0.4rem;
    width: 80%;
    border: 1px solid #bdbdbd;
  }
  .faq_index {
    display: flex;
    justify-content: space-between;
    padding-left: 0.4rem;
    width: 80%;

    .delete {
      cursor: pointer;
    }
    .delete_icon {
      cursor: pointer;
    }
  }
}
.FaqAction {
  min-width: 6rem;
  position: absolute;
  background: white;
  right: 3rem;
  top: 3rem;
  margin-top: -1rem;
  z-index: 10;
  width: 9.063rem;
  // height: 5.875rem;
  // border-radius: 10px;
  box-shadow: 4px 4px 4px 4px #bdbdbd;
  // box-shadow: 10px 10px;
  border-radius: 1rem;
}

.FaqAction-text {
  text-align: left;
  padding: 1rem 1rem;
  border-bottom: 1px solid gainsboro;
  margin-bottom: 0;
  cursor: pointer;
}
