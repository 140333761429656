@import 'styles/variables.scss';

.sidebar {
  background: $sidebar_background_color;
  top: $headerbar_ht;
  left: 0;
  width: $sidebar_width;
  flex: 0 0 $sidebar_width;
  height: calc(100vh - $headerbar_ht);
  box-shadow: 10px 0 10px -10px #888;
  transition: all 0.5s ease;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sidebar-item {
  min-height: 57px;
  align-items: center;
  justify-content: start;
  padding: 1rem;
  border-bottom: 1px solid $color_grey;
  opacity: 0.7;
  a {
    color: white !important;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
  }
}

.sub-menu {
  background-color: white;
  padding: 1rem;
  li {
    padding: 1rem;
    border-bottom: 1px solid #505050;
    a {
      color: black !important;
      text-decoration: none !important;
    }
  }
}

.active-item {
  opacity: 1;
  background-color: #303750;
}

.c-grey {
  color: #909090;
}