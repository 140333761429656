.TabBar {
  display: flex;
  padding: 5px 0 5px 0;

  .tab-item {
    margin-right: 5px;
    cursor: pointer;
    padding: 8px 16px 8px 16px;
    border-radius: 20px 20px 0px 0px;
    border: 1px;
    // gap: 10px;

    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
  }
  .active {
    background-color: #147ddf;
    color: #ffffff;
    // background: linear-gradient(0deg, #147ddf, #147ddf),
    //   linear-gradient(0deg, #ffffff, #ffffff);
  }
  .inactive {
    border: 1px solid #147ddf;
    background-color: #ffffff;
    color: #147ddf;
  }
}
