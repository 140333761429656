.SmallBanner-container {
  margin-top: 1rem;
  background-color: #f8f8f8;
  &__SmallBanner {
    width: 100%;

    height: auto;
  }

  &__section-one {
    border: 1px dashed #d1d1d1;
    padding: 0.7rem;
    margin-bottom: 0.5rem;
  }

  &__Image-Container {
    width: 100%;
    padding: 1rem;

    & .attachment {
      padding: 0;
    }
  }

  & .card__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #d1d1d1;
    padding-bottom: 0.7rem;
    margin-bottom: 0.7rem;

    // &__section {
    //   font-weight: bold;
    // }
    & p {
      margin-bottom: 0;
      font-weight: bold;
    }

    & img {
      cursor: pointer;
    }
  }
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #bfd02e;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.newdesigntable {
  table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  table td,
  table th {
    border: 1px solid #e7e7e7;
    padding: 8px;
    text-align: center;
  }

  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f8f8f8;
    color: #d4d4d4;

    & .arrow {
      height: 1.6rem;
      margin-left: 0.2rem;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .pagination {
    margin: auto !important;
    padding: 1.5rem !important;
    align-items: center !important;
    justify-content: center !important;

    & button {
      margin-right: 1rem;
      padding: 0.5rem;

      :nth-last-child(1) {
        margin-right: 0;
      }
    }

    & span {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
