@import 'styles/variables.scss';

.ArticleModal {
  position: absolute;
  inset: 61% 0% 0% 95% !important;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 0 !important;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  height: 100%;
  transform: translate(-48%, -61%) !important;

  &__Overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
}

.Filters {
  display: flex;
  width: 55%;
  align-items: center;
  justify-content: space-between;

  &_title {
    color: $color-black-primary;
    font-size: $fs15;
  }
}

.wrapped-component {
  width: 50%;
}
.Filter {
  margin: 0 1rem;
}
.Wrong-Icon {
  cursor: pointer;
}
