@import 'styles/variables.scss';

.searchBar {
  // width: 100%;
  width: 17rem;
  display: flex;
  // float: right;
  align-items: center;
  background-color: $color-white-primary;
  border-radius: 0.5rem;
  border: solid 1px grey;
  height: 2.75rem;
  margin-right: 2rem;

  &__searchIcon {
    // width: 1.5rem;
    height: 1rem;
    object-fit: contain;
    margin-left: 1rem;
  }

  &__textInput {
    border-radius: 1rem;
    background-color: $color-white-primary;
    border: none;
    width: 100%;
    color: $color-black-primary;
    padding: 0.5rem 0.5rem;
    outline: none;

    &::placeholder {
      opacity: 0.6;
      font-size: 1rem;
      line-height: 1.41;
      margin-left: 1.7rem;
    }
  }

  & .logonWrapper-text {
    margin-top: 0;
    margin-bottom: 0;

    & input {
      border: none;
      height: initial;

      &:focus {
        border-color: initial;
        box-shadow: none;
      }
    }
  }
}
