@import 'styles/variables.scss';
.Banner_size {
  width: 362px;
  height: 221px;
  opacity: 1;
  margin-left: 3rem;
  cursor: default;
}

.categories_box {
  &_title_section {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
  }

  &_subcatgories_heading {
    border-top: 1px solid #dbdbdb;
    padding: 1rem 0 0 0;
    margin: 2rem 0 2rem 0;
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    &_right {
      width: 28rem;

      &_top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;

        &_action {
          width: 10rem;

          &_complete {
            display: flex;
            justify-content: space-between;
            // span {
            //   // margin-right: 1rem;
            // }
          }
        }
        &_key {
          width: 10rem;
        }
      }
      &_bottom_message {
        color: #e1ad2d;
        width: 28rem;
      }
    }

    &_left {
      &_key {
        margin-bottom: 1rem;
      }
    }
  }

  &_subcatgories_section {
    border-top: 1px solid #dbdbdb;

    padding: 1rem 0 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &_right {
      width: 28rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &_action {
        width: 10rem;

        &_section {
          display: flex;
          justify-content: space-between;

          &_viewdelete {
            display: flex;
            text-decoration: underline;
            margin-right: 3rem;
            font-weight: 700;
            cursor: pointer;
            &_image {
              margin-right: 1rem;
              height: 1rem;
              width: 1rem;
            }
          }
          &_completestatus {
            color: #5bb33e;
          }
          &_incompletestatus {
            color: #ec9898;
            text-align: end;
          }
        }
      }
      &_key {
        width: 10rem;
      }
    }
    // &_left{

    // }
  }
  &_divide {
    margin: 0 2rem 0 2rem;
    border-right: 1px dashed #d1d1d1;
  }

  &_image {
    width: 100% !important;
    // height: 168px !important;
    margin-top: 20px;
    object-fit: cover !important;
  }

  &_gif {
    // height: 280px !important;
    height: auto !important;
  }

  &_top {
    margin-top: 16px;
    margin-bottom: 1rem;
    padding: 0 16px;

    &_title {
      font-size: 16px;
    }

    &_desc {
      padding-top: 2px;
      font-size: 14px;
      color: rgba($color: $color-black-primary, $alpha: 0.54);
    }
  }

  &_cta {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  &_content {
    padding: 0 16px;
    margin-bottom: 20px;
    align-items: center;
    flex-direction: column;
  }
  &_feed__attachment {
    // width: 280px;
    // height: 144px;
    max-width: 280px;
    max-height: 100px;
    opacity: 1;
    // margin-left: 3rem;
    cursor: default;
  }
}

.categories-button-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 2rem 0;

  & .button:not(:last-child) {
    margin-right: 1rem;
  }

  &__title {
    font-size: 1.2rem;
    margin-bottom: 0;
  }

  &__right {
    display: flex;
  }
}
.isAddButtonDisabledtrue {
  padding: 20px;
  margin-bottom: 20px;
  // bottom: 10%;
  box-shadow: 10px 10px 10px 10px #888 !important;
  z-index: 999;
}

.categoriespublishbuttondiv {
  display: flex;
  justify-content: flex-end;
}

.subcategory_container {
  &__top {
    &_topHeadingtoggle {
      display: flex;
    }

    .Widget-container {
      margin-top: 1rem;
      background-color: #f8f8f8;
      &__Widget {
        width: 100%;

        height: auto;
      }

      &__section-one {
        border: 1px dashed #d1d1d1;
        // padding: 0.7rem;
        margin-bottom: 0.5rem;
        // text-align: center;
      }

      &__Image-Container {
        width: 100%;
        padding: 1rem;

        & .attachment {
          padding: 0;
        }
      }
    }

    &__namesection {
      display: flex;
      align-items: center;
      &_content {
        padding: 0 16px;
        margin-bottom: 20px;
        align-items: center;
        flex-direction: column;
      }
      &_feed__attachment {
        // width: 280px;
        // height: 144px;
        max-width: 280px;
        max-height: 100px;
        opacity: 1;
        // margin-left: 3rem;
        cursor: default;
      }
    }

    &__filterSearch {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
    }
  }
}

.top__productSearch {
  position: relative;
  padding: 1rem;
}
.top__productSearch__dropdown {
  // border: 1px solid black;
  border-radius: 20px;
  // box-shadow: #d1d1d1;
  box-shadow: 0 10px 11px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 25rem;
  z-index: 1;
  overflow-x: auto;
  max-height: 19rem;
}

.top__productSearch__dropdown__medicinename_new {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid grey;
  // width: 20rem;

  background-color: #ffffff;

  img {
    width: 20px;
    height: 20px;
  }
}
.top__productSearch__dropdown__medicinename_already {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid grey;
  background-color: #f8f8f8;

  img {
    width: 20px;
    height: 20px;
  }
}
.categorySearchBar {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.5rem;
  border: solid 1px grey;
  height: 3.75rem;
  margin: 1rem 2rem 1rem 0;
}
.button_section {
  display: flex;
  justify-content: end;
}
.subcategory_container_topHeadingtoggle {
  display: flex;
  justify-content: space-between;
}

.selectcategories_checkbox__list {
  display: flex;
  flex-wrap: wrap;
  .medpaySubCategoriesList_checkbox {
    margin: 0 5px 0 5px;
    padding-right: 5px;
  }
}

.categories-Widget-container-heading {
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem 0 1rem 0;
}
.medpay_subcategory_container_topHeadingtoggle {
  display: flex;
  justify-content: space-between;
}

.productSearchLoader {
  z-index: 1;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  /* margin: 15px auto; */
  position: absolute;
  color: green;
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
  left: 20%;
  // padding: 1rem;
  top: 80%;
  transform: translate(-50%, -50%);
}

@keyframes animloader {
  0% {
    box-shadow: -38px -6px, -14px 6px, 14px -6px;
  }

  33% {
    box-shadow: -38px 6px, -14px -6px, 14px 6px;
  }

  66% {
    box-shadow: -38px -6px, -14px 6px, 14px -6px;
  }

  100% {
    box-shadow: -38px 6px, -14px -6px, 14px 6px;
  }
}

.subcategorybuttonsection {
  display: flex;
  margin: 10px 0 10px 0;
}
