.Version-button {
  margin-top: 2rem;
}
.update-wrapper {
  background: #f8f8f8;
  padding: 2rem;
  // border: 1px dashed #d1d1d1 ;
  background-image: linear-gradient(
      to right,
      #d1d1d1 50%,
      rgba(255, 255, 255, 0) 0%
    ),
    linear-gradient(to right, #d1d1d1 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#d1d1d1 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#d1d1d1 50%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom, top, left, right;
  background-size: 10px 2px, 10px 2px, 2px 10px, 2px 10px;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  margin: 2rem 0;
  h4 {
    background-image: linear-gradient(
      to right,
      #d1d1d1 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .cdropdown {
    display: flex;
    align-items: center;
    gap: 40px;
    & > label {
      min-width: 150px;
    }
  }
  .Dropdown-root {
    width: 35%;
  }
}
.prod-version {
  margin-bottom: 2rem;
  .form-input {
    width: auto;
    min-width: 300px;
  }
}
.optional-text {
  margin-top: 55px;
  margin-bottom: -15px;
}
.tab-switch {
  margin: 2rem 0;
  input {
    display: none;
    &:checked + span {
      background: #fff;
      color: #7e7e7e;
      & + span {
        background: #147ddf;
        color: #fff;
      }
    }
  }
  label {
    border: 1px solid #d6d6d6;
    border-radius: 50px;
    width: auto;
  }
  span {
    padding: 8px 18px;
    display: inline-block;
    min-width: 100px;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
    color: #7e7e7e;
    &:nth-of-type(1) {
      background: #147ddf;
      color: #fff;
    }
  }
}
