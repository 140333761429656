.badge-default{
background: #719720;
&:before{
    content:" ";
    border-top: 2px solid;
    border-bottom: 2px solid;
    width: 9px;
    height: 7px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
}
}

.badge-list {
    display: flex;
    gap:5px;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
    flex-flow: wrap;
    li{
        padding: 2px 5px;
        border-radius: 5px;
        color: #fff;
        font-size: 0.75rem;
        line-height: 1.3;
        background: #719720;
        text-transform: uppercase;
    }
    &.danger-badge{
       li{
        background: #e34d4d;
       }
    }
}
.left-aligne-table{
    tr{
        th,td{text-align: left;}
    }
}

.two-field{
    .logonWrapper-text{
        margin-top: 0;
    }
}

.form-wrapper{

    .video-field-wrap{
        .form-field{
            width: 30%;
        }
    }
}

// .course-section {
//     margin-top: 1rem;
//     background-color: #f8f8f8;
//     border: 1px dashed #d1d1d1;
//     padding: 0.7rem;
//     margin-bottom: 0.5rem;
//     &_title{
//         display: flex;
//     justify-content: space-between;
//     border-bottom: 1px dashed #d1d1d1;
//     padding-bottom: 0.7rem;
//     margin-bottom: 0.7rem;
//     h4{margin: 0;font-weight: bold;}
//     }

// }
// .form-wrapper{
//     display: flex;
//     flex-flow: wrap;
//     justify-content: space-between;
//     .form-field{
//         width: 48%;
//     }
//     &.quarter-field{
//         .form-field{
//             width: 16%;           
//             label{display: block;margin-bottom: 10px;}
//             &:first-child{width: 40%;}
//             &:nth-child(2){width: 20%;}
//         }
//     }
// }
// .divider-hr{
//     margin: 2rem 0;
//     border-top: 1px dashed #d1d1d1;
//     opacity: 1;
// }
// .multiselect-btn{
//     display: flex;
//     flex-flow: wrap;
//     gap:8px;
//     list-style: none;
//     padding: 0;
//     font-size: 0.8rem;
//     margin-top:10px;
//     li{
//         border: 1px solid #147ddf;
//         padding: 14px 16px;
//         color:#147ddf;
//         border-radius: 4px;
//         cursor: pointer;
//         &.active,&:hover{background:#147ddf;color:#fff;}
//     }
// }
// .two-field{
//     display: flex;
//     align-items: center;
//     gap: 15px;
//     color: #3b3f42;
// }
// .frontend-search{
//     label{display: block;margin-bottom: 15px;}
//     .badge-list{margin-bottom: 15px;
//     a{color: #fff;
//         text-decoration: none;
//         font-size: 16px;
//         line-height: 1;
//         margin-left: 5px;
//         position: relative;
//         top: 2px;}
//     }

// }

// .video-field-wrap{
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     .form-field{
//         width: 30%;
//     }
//     .or-text{
//         display: flex;
//     align-items: center;
//     margin-top: 3.1rem;
//     padding: 1rem;
//     font-weight: bold;
//     }
//     .duration-field{margin-top: 1.5rem;width:18%;
//     label{margin-bottom: 0.5rem;}
//     }
// }