@import 'styles/variables.scss';

.cms-content__container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.table {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.my-table__arrow {
  padding: 0.5rem;
  font-size: 1rem;
}
.content-container__actionBox {
  display: flex;
  justify-content: space-between;
}
.content-container__perPage {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.content-container__filterSearch {
  display: flex;
  column-gap: 0.5rem;
}

.content-container__pagination {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  .cursor-pointer {
    cursor: pointer;
  }
  #active-page {
    background-color: $light_grey_1;
  }
}

.custom-select {
  padding: 0.5rem 1rem;
  border: 1px solid $light_grey_1;
  border-radius: 0.4rem;
}
.table-menu {
  label {
    cursor: pointer;
  }
}

.search_and_title {
  display: flex;
  justify-content: space-between;
}

// .react-table-pagination {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   column-gap: 1rem;
//   margin-top: 0.8rem;
//   button {
//     font-size: 1rem;
//     padding:0 0.5rem;
//     border: 1px solid black;
//     &:active , &:hover {
//       background-color: $light_grey_1;
//     }
//     &:disabled {
//       cursor: not-allowed;
//     }
//   }
// }
