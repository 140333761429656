@import "styles/variables.scss";

.blogs {
  &_container {
    width: 100%;
    //  margin-top: 2rem;
    display: flex;
  }

  &_box {
    width: 12rem;
    height: 6rem;
    padding: 0.7rem;
    margin-right: 1rem;
    text-align: left;
    border: 1px solid $color-black-primary;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &_number {
    font-weight: bold;
    font-size: 2rem;
  }

  &_text {
    font-size: $fs10;
    font-weight: 400;
    margin-bottom: 0;
  }

  &_data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
  }

  &_filter {
    display: flex;
    justify-content: center;
    align-items: center;

    &_card {
      border: 1px solid $color_black_primary;
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;
      padding: 0.2rem 1rem;
      cursor: pointer;
      color: $color-gray-primary;

      &.active {
        background-color: $color-primary;
        color: $color-black-primary;
      }
    }

    &_card1 {
      border: 1px solid $color_black_primary;
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      padding: 0.2rem 1rem;
      cursor: pointer;
      color: $color-gray-primary;
      &.active {
        background-color: $color-primary;
        color: $color-black-primary;
      }
    }
  }

  &_offer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &_switch {
    padding-left: 1rem;
  }
  &_points {
    width: 50% !important;
  }
  &_p {
    margin: 0;
  }
  &_button {
    background-color: $color-primary !important;
    color: $color-black-primary;
    border: none;
    padding: 0.4rem 0.6rem;
    border-radius: 0.5rem;
    margin: 0;
    width: 10rem;
    margin-left: 1rem;
  }
  &_discounts {
    // border-top: 1px solid lightgray;
    margin-top: 2rem;
    padding-top: 2rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.c-select {
  height: 3rem;
  border: 1px solid #bdbdbd;
  padding: 0 0.75rem;
  border-radius: 0.25rem;
}
