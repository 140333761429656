@import 'styles/variables.scss';

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04aa6d;
  color: white;

  & .arrow {
    height: 1.6rem;
    margin-left: 0.2rem;
  }
}

.pointer {
  cursor: pointer;
}

.pagination {
  margin: auto !important;
  padding: 1.5rem !important;
  align-items: center !important;
  justify-content: center !important;

  & button {
    margin-right: 1rem;
    padding: 0.5rem;

    :nth-last-child(1) {
      margin-right: 0;
    }
  }

  & span {
    margin-left: 1rem;
    margin-right: 1rem;

    // &:not(:last-child) {
    //   margin-left: 0rem;
    // }
  }
}
