@import "styles/variables.scss";

.cms_tags_table_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  justify-content: flex-start;
  align-items: start;
}
.cms_tags_table_container__actionBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.cms_tags_table_container__filterSearch {
  display: flex;
  column-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}
.cms_tags_table_container__items_perPage {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.cms_tags_table_container__pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  .cursor-pointer {
    cursor: pointer;
  }
  #active-page {
    background-color: $light_grey_1;
  }
}
