.Banner_size {
  width: 362px;
  height: 221px;
  opacity: 1;
  margin-left: 3rem;
  cursor: default;
}

h1 {
  display: flex;
  justify-content: space-between;
}

.newdropdown {
  width: 50%;
  margin: 1.5rem;
}

.cdropdown {
  margin-top: 1.5rem;

  & label {
    margin-bottom: 0.5rem;
  }
}
