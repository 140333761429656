@import 'styles/variables.scss';

.pageloader {
  // background-color: rgba($color: $black_color, $alpha: 0.3);
  // width: calc(100vw - 16rem);
  width: 100vw;
  height: 100vh;
  // height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.Modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: $black_color, $alpha: 0.5);
  inset: initial;
  border: none;
  transform: initial;
  padding: 0;
  margin-right: initial;
  z-index: 10;

  &__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: $black_color, $alpha: 0.5);
    z-index: 10;
  }
}
