@import 'styles/variables.scss';

.tc {
  overflow: scroll;
  height: 100vh;
  font-size: 1rem;

  &__header {
    background-color: $color_grey;
    color: $white_color;
    text-align: center;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }

  &__content {
    padding: 1.2rem;
  }

  & .page-loader {
    width: 100vw;
  }

  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6 {
  //   margin: 0;
  //   font-weight: normal;
  // }

  // p {
  //   margin-top: 0;
  //   margin-bottom: 0;
  // }

  // ol,
  // ul {
  //   // padding-left: 2rem;
  //   padding: 0 1rem;
  //   margin-top: 0;
  //   margin-bottom: 0;
  // }

  hr {
    opacity: 0.25;
    margin: 1rem 0;
    background-color: #cbb;
  }

  & .nocontent {
    display: flex;
    justify-content: center;
    padding: 2rem;
  }
}
