.checklist {
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.form-check-input {
  margin-right: 0;
}

.check {
  display: flex;
  //   margin-top: 2rem;
  margin-top: 1rem;
}
