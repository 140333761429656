@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  @mixin verticalCenter {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
  }
  
  @mixin horizontalScollPane {
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
  }
  
  @mixin flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  