.ActionUpload {
  min-width: 6rem;
  border: 1px solid black;
  position: absolute;
  background: white;
  left: 8rem;
  // right: 3rem;
  top: 4rem;
  margin-top: -1rem;
  z-index: 10;
}

.ActionUpload-text {
  text-align: left;
  // padding-left: 1rem;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid gainsboro;
  margin-bottom: 0;
  cursor: pointer;
}

// .ActionUpload {
//   width: 12rem;
//   height: fit-content;
//   border: 1px solid black;
//   position: absolute;
//   background: white;
//   right: 5rem;
//   margin-top: 0rem;
//   padding-top: 0.7rem;
//   left: 10rem;
// }

// .ActionUpload-text {
//   text-align: left;
//   border-bottom: 1px solid gainsboro;
//   margin-bottom: 0;
//   cursor: pointer;

//   &:nth-child(2) {
//     margin-top: 1rem;
//   }
// }
