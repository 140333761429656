// .Radio {
//   width: 100%;
//   display: flex;
// }

.radio {
  &_container {
    display: flex;

    & > div:not(:first-child) {
      margin-left: 1rem;
    }
  }

  &_url {
    margin-top: 1rem;

    & input {
      display: block;
      width: 100%;
      height: 3rem;
      padding: 0 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.6;
      color: #4f4f4f;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid #bdbdbd;
      cursor: text;
      border-radius: 0.25rem;
    }
  }
}
