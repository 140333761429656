.radio-btn {
  display: flex;
  align-items: center;

  & input {
    margin-right: 0.5rem;
  }

  &_addoption {
    padding: 0.5rem 1rem;
    height: 1.7rem;
    border: none;
    border-radius: 2.8rem;
    box-shadow: 0 10px 11px 0 rgb(0 0 0 / 20%);
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    background-image: initial;
    background-color: #505050;
  }

  &_remove {
    margin-left: 0.2rem;
    cursor: pointer;
  }
}
