.slide-details-container {
  width: 100%;
  border: 1px solid black;
}

.upload-btn {
  position: relative;
  overflow: hidden;
  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.5);
    opacity: 0;
  }
}

.media-details-box {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  column-gap: 0.8rem;
}
.quiz-add-btn-box {
  display: flex;
  justify-content: center;
  button,
  input {
    border-radius: 0.4rem;
    padding: 0.2rem;
  }
}

.edit-quiz-options-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem 0;
  button,
  input {
    border-radius: 0.4rem;
    padding: 0.2rem;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 0.3rem;
  }
  .option-inputAndBtn {
    display: flex;
    justify-content: space-between;
    gap: 0 0.1rem;
  }
}
