@import 'styles/variables.scss';

.button {
  // width: 100%;
  // width: 10rem;
  padding: 0.5rem 2.4rem;
  height: 2.7rem;
  border: none;
  border-radius: 2.8rem;
  box-shadow: 0 10px 11px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to left, #bfd023 99%, #92b852 1%);
  // margin-top: 1rem;
  font-size: $fs10;
  font-weight: 600;
  text-align: center;
  color: $color-white-primary;
  cursor: pointer !important;
  // margin-top: 1rem;
  &.bg-primary{
    background-image: linear-gradient(to left, #0d6efd 99%, #0d6efd 1%);
  }
  &.bg-success{
    background-image: linear-gradient(to left, #198754 99%, #198754 1%);
  }
  &.bg-danger{
    background-image: linear-gradient(to left, #dc3545 99%, #dc3545 1%);
  }
  &:disabled,
  &[disabled] {
    background-image: linear-gradient(to left, #ddd 50%, #ddd 50%);
  }
  // FOr small button Start
  &.btn-sm{
    height: auto;
    padding: 5px 20px;
    margin: 0;
  }
  // FOr small button End
}
