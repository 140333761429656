// .form-control {
//   display: block;
//   width: 100% !important;
//   padding: 0.375rem 0.75rem;
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.6;
//   color: #4f4f4f;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 1px solid #bdbdbd;
//   appearance: none;
//   border-radius: 0.25rem;
//   // padding-bottom: 10rem;
//   margin-bottom: 1rem;
// }

.txtarea {
  margin-top: 1.5rem;

  & label {
    margin-bottom: 1rem;
    span{
      color: #ff0000;
    }
  }

  & textarea {
    display: block;
    width: 100% !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #4f4f4f;
    background-color: #fff;
    border: 1px solid #bdbdbd;
    border-radius: 0.25rem;
  }
}
