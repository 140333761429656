@import 'styles/variables.scss';

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;

  & .arrow {
    height: 1.6rem;
    margin-left: 0.2rem;
  }
}

.pointer {
  cursor: pointer;
}

.pagination {
  margin: auto !important;
  padding: 1.5rem !important;
  align-items: center !important;
  justify-content: center !important;

  & button {
    margin-right: 1rem;
    padding: 0.5rem;

    :nth-last-child(1) {
      margin-right: 0;
    }
  }

  & span {
    margin-left: 1rem;
    margin-right: 1rem;

    // &:not(:last-child) {
    //   margin-left: 0rem;
    // }
  }
}

.sharebutton {
  background-color: #c2fbd7;
  border-radius: 10px;
  box-shadow: rgba(44, 187, 99, 0.2) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.15) 0 1px 2px, rgba(44, 187, 99, 0.15) 0 2px 4px,
    rgba(44, 187, 99, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px,
    rgba(44, 187, 99, 0.15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  font-weight: 400;
  margin-top: 0.5rem;
    margin-bottom: 0.8rem;
    margin-left: 1rem;
    margin-right: 1rem;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  // display: table-cell;
  // vertical-align: inherit;
}
