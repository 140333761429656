.webinar-card {
  width: 100%;
  height: auto;
  margin-top: 2rem;
  display: flex;
  justify-content: space-evenly;
  // display: table-cell;
  // vertical-align: inherit;
}
.webinar-host {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1rem;
}
.webinar-container {
  margin-top: 1rem;
}
.webinar-host-but {
  margin: 0.7rem 0rem 0.5rem;
}

.webinar-speeker {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1rem;
  // border: 1px solid black;
}

.speekertext {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
}
.speaker-attachment {
  height: 85px;
  width: 85px;
  border-radius: 10px;
  opacity: 1;
  margin-left: 3rem;
  cursor: default;
}
.webinar-img {
  max-height: 120px;
  max-width: 360px;
  // height: 360px;
  // width: 120px;
  // border-radius: 83px;
  opacity: 1;
  margin-left: 3rem;
  cursor: default;
}
.webinar-vali {
  color: red;
  font-size: 0.8rem;
  margin: 0.3rem;
}
