.Habit_Container {
  border: 1px solid rgba(149, 157, 165, 0.2);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  //   margin-top: 1rem;
  padding: 0.5rem;
}

.CreateHabit {
  border: 1px solid gainsboro;
  margin-top: 1rem;
  padding: 0.5rem;
}

.tips {
  padding-top: 1rem;
}

.radio-tip {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  width: 13rem;
}

.update-button {
  margin-top: 1rem;
}

.habit-detail-img {
  height: 362px;
  width: 362px;
  opacity: 1;
  margin-left: 3rem;
  cursor: default;
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
}

.habit-img {
  height: 176px;
  width: 106px;
  opacity: 1;
  margin-left: 3rem;
  cursor: default;
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
}
