@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.ql-font-poppins {
  font-family: 'Poppins';
}

.ql-font-oswald {
  font-family: 'Oswald';
}

.ql-font .ql-picker-options .ql-picker-item[data-value="poppins"]::before {
  content: 'Poppins';
}

.ql-font .ql-picker-options .ql-picker-item[data-value="oswald"]::before {
  content: 'Oswald';
}

.ql-picker.ql-font {
  .ql-picker-label {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
      text-transform: capitalize;
    }
  }
}

.cipla-editor {
  margin-top: 1.5rem;

  .ql-editor {
    min-height: 400px;
  }

  & label {
    margin-bottom: 1rem;
    .asterik{
      color: #ff0000;
      font-weight: 400;
    }
  }
}.ql-font-poppins {
  font-family: 'Poppins';
}

.ql-font-oswald {
  font-family: 'Oswald';
}

.ql-font .ql-picker-options .ql-picker-item[data-value="poppins"]::before {
  content: 'Poppins';
}

.ql-font .ql-picker-options .ql-picker-item[data-value="oswald"]::before {
  content: 'Oswald';
}

.ql-picker.ql-font {
  .ql-picker-label {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
      text-transform: capitalize;
    }
  }
}

.cipla-editor {
  margin-top: 1.5rem;

  & label {
    margin-bottom: 1rem;
    .asterik{
      color: #ff0000;
      font-weight: 400;
    }
  }
}