@import 'styles/variables.scss';

.headerback {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  &_backicon {
    cursor: pointer;
  }

  &_title {
    font-weight: bold;
    padding-left: 1rem;
  }
}
