@import 'styles/variables.scss';

.page-loader {
  width: calc(100vw - 16rem);
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.loader {
    // margin: 100px auto auto auto;
    border: 0.5rem solid $light_grey_48;
    border-radius: 50%;
    border-top: 0.5rem solid $color-primary;
    width: 6rem;
    height: 6rem;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }