.Banner-container {
  margin-top: 1rem;

  &__Banner {
    width: 100%;
    border: 1px solid #000000;
    height: auto;
    background-color: white;
  }

  // &__section-one {
  //   margin-bottom: 0.5rem;
  // }

  // &__section {
  //   margin-bottom: 0.5rem;
  // }

  &__Image-Container {
    width: 100%;
    padding: 1rem;

    & .attachment {
      padding: 0;
    }
  }

  & .card__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.7rem;

    & p {
      margin-bottom: 0;
    }

    & img {
      cursor: pointer;
    }
  }
}
