.tab-switch{
  margin: 2rem 0;
   input{display: none;
   &:checked  + span {
     background: #fff;color:#7e7e7e;
     & + span{
       background: #147ddf ;color:#fff;
     }
   }
   }
   label{
     border: 1px solid #d6d6d6;
     border-radius: 50px;
     width: auto;
   }
   span{
     padding: 8px 18px;display: inline-block;min-width: 100px;text-align: center;border-radius: 50px;cursor: pointer;color:#7e7e7e;
     &:nth-of-type(1){background: #147ddf ;color:#fff;}
   }
 }
