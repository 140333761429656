@import 'styles/variables.scss';

.newattachment {
  display: flex;
  // flex-direction: column;
  padding: 1.5rem 2rem 0 0;
  cursor: pointer;
  position: relative;

  .newattachment_text{
    .asterisk{
      color: $color_red_primary;
      font-size: 1rem;
      font-weight: 400;
    }
  }
  
  &_text {
    font-weight: bold;
    font-size: $fs12;
    margin-left: 0.3rem;
    margin-bottom: 0;

    .newjpgsizelabel {
      font-weight: normal;
      font-size: 0.8rem;
    }
  }

  &__input-file {
    display: none;
  }

  &__selimage {
    height: 10rem;
    // margin-left: 3rem;
    cursor: default;
  }

  &_img_wrapper {
    margin-top: 2rem;
    position: relative;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.4;
    color: #4f4f4f;
    background-color: #ffffff;
    border: 1px solid #bdbdbd;
    border-radius: 0.25rem;
    cursor: pointer;
    text-decoration: underline;

    &_close {
      padding: 0.2rem;
      background-color: $color-white-primary;
      position: absolute;
      content: '';
      width: 2rem;
      height: 2rem;
      right: 0;
      top: 0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      & img {
        width: 1rem;
      }
    }
  }
}
