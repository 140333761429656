.container{
    padding: 10px;
}
.separator{
    margin-top: 20px;
    background-color: #04aa6d;
    color: white;
    padding: 10px;

}
.separator p{
    margin-bottom: 0px;
    font-weight: 600;
}

.userInput .txtarea label{
   font-size: 14px;
}
.userInput .logonWrapper-text label{
    font-size: 14px;
}
.userInput .form-label{
    font-size: 14px;
}

.userFormArea{
    margin-top: 1.5rem;

    label{
        font-size: 14px;
        margin-bottom: 0rem;
    }
}
.upload-buttons{
    display: flex;
    column-gap: 20px;
    position: relative;
}
.content-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 40px;

    h4{
        font-size: 15px;
    }
}
.content-selector{
     margin-top: 1.5rem;
    label{
        font-size: 14px;
    }
     .head-label{
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 1rem;
     }
}

.horizontal-align{
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
}
.dropdown-area{
    width: 50%;

    .cdropdown{
        margin-top: 0;
    }
}
.input-container{
    width: 40%;
}

.file-section{
    label{
        display: block;
        font-size: 12px;
    }
}
.divider{
    display: flex;
    align-items: center;
}


.cancel{
    display: inline;
    padding-left: 10px;
    font-size: 10px;
    color: red;
    cursor: pointer;
}
.form-input:disabled{
    background-color: rgba(149, 149, 149, 0.212);
}
.Dropdown-disabled{
    background-color: rgba(149, 149, 149, 0.212) !important;
}

.colorpicker{
     margin-top: 1.5rem;
    label{
        font-size: 14px;
        display: block;
    }
    p{
        display: inline;
        padding-left: 10px;
    }
}

.upload-label{
    label{
        display: block;
    }
   
}

.custom-select:disabled{
    background-color: rgba(149, 149, 149, 0.212);
    opacity: 1;
}

.multiSelectContainer{
    &.disable_ms{
        background-color: rgba(149, 149, 149, 0.212);
        opacity: 1;
    }
}

.disbale-ctaType{
    .radio-btn{
        background-color: rgba(149, 149, 149, 0.212);
        opacity: 1;
        width: 100%;
        max-width: 260px;
    }
    .form-check-input{
        &:disabled{
            opacity: 1;
        }
    }
}