@import "styles/variables.scss";

.cms-shorts__container,
.cms-quiz__container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.shorts-con__inputs,
.shorts-con__slide-details,
.shorts-con__endless-scroll {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.shorts-con__main-box {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  .media-rm-btn {
    padding: 0.4rem 0.2rem;
    border-radius: 0.5rem;
    border: none;
    background-color: rgb(254, 111, 111);
    transform: scale(0.8);
    color: #fff;
  }
}
.addedShorts-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin: 1.5rem 0;
}
.shorts-con__select-box {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  select {
    max-width: 10rem;
    min-width: 5rem;
  }
}
.shorts-con__endless-scroll {
  margin: 1rem 0;
  .form-check-label {
    font-size: 0.8rem;
  }
}

.shorts-con__link-box {
  display: flex;
  justify-content: space-around;
}

.shorts-con__link-inputs {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.shorts-con__date-inputs,
.quiz-con__date-inputs {
  display: flex;
  justify-content: space-around;
}
.shorts-con__thumbnail-box {
  max-width: 40%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin: 1rem 0;
}
.shorts-con__actions,
.quiz-con__actions {
  display: flex;
  column-gap: 2rem;
  align-items: center;
  label {
    cursor: pointer;
  }
}

.quiz-con__table {
  font-size: 0.8rem;
  label {
    font-size: 1rem;
    padding: 0.5rem 0;
  }
}
.quiz-con__file-uploads {
  display: flex;
  column-gap: 2rem;
  justify-content: center;
  align-items: center;
  .file-upload__btns {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
}

.quiz-options-container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem 0;
  ul {
    li {
      margin: 0.4rem 0;
    }
  }
  .quiz-option-addinput {
    box-shadow: 0 0 0 3px rgb(12, 238, 12);
  }
}
.pr-3{
  padding-right: 1rem;
}
