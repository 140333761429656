.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 2rem 0;

  & .button:not(:last-child) {
    margin-right: 1rem;
  }

  &__title {
    font-size: 1.2rem;
    margin-bottom: 0;
  }

  &__right {
    display: flex;
  }
}
