.blur-text {
  color: #929292;
  font-family: Poppins;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  //   text-align: left;
}
.dashed-border {
  border: 2px dashed #d1d1d1;
  tr {
    background-color: #f8f8f8;
    &:nth-child(even) {
      background-color: #f8f8f8;
    }
    &:hover {
      background-color: #fff;
    }
  }
  th {
    background: #f8f8f8;
    color: #a3a3a3;
    text-align: left !important;
    padding: 12px 20px;
  }
  td {
    text-align: left;
    padding: 12px 20px;
  }

  .td-white {
    background: #fff;
  }
}
