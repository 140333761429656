@import 'styles/variables.scss';
.blogsForm {
  padding: 2rem;
  width: 80%;
  &_data1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;

    & img {
      cursor: pointer;
    }
  }
  &_heading {
    font-weight: bold;
    margin-bottom: 0;
    padding-left: 1rem;
  }
  &_textArea {
    // background-color: $color-black-primary;
    color: $color-white-primary;
  }
  &_button {
    // background-color: $color-primary !important;
    color: $color-black-primary;
    border: none;
    padding: 0.4rem 0.6rem;
    border-radius: 0.5rem;
    margin: 0;
    width: 10rem;
    margin-top: 1rem;
  }
  &_switch {
    padding-left: 1rem;
  }
  &_alert {
    color: $color_red_primary;
    padding: 0.2rem 0 0 0;
    margin: 0;
  }
  &_alert1 {
    color: $color_red_primary;
    padding: 0.2rem 0 0 1rem;
    margin: 0;
  }
  &_image {
    border: 1px solid $color-black-primary;
    width: 10rem;
    height: 10rem;
  }
}
.pt-3 {
  display: block !important;
}

.pt-3-d-flex {
  margin-top: 2rem;
  display: inline-flex !important;
}

.Preview-text {
  display: flex;
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  cursor: pointer;
  width: fit-content;
}

.validate-Title {
  display: flex;
  color: $color_red_primary;
  font-size: $fs12;
}
.drop-down {
  display: flex;
  width: 50%;
  justify-content: space-between;
  margin-top: 1rem;
}

.preview {
  &_wrapper {
    background: $color-preview-bg;
    width: 375px;
    height: 100%;
    margin: auto;
    padding: 16px 0;
    overflow: scroll;
    position: relative;
  }

  &_content {
    // height: 90vh;
    height: 95vh;
    // padding-left: 1rem;
    // background-color: red;

    & .article-detail {
      padding: 0;
      line-height: 1.4;

      & img {
        width: 100%;
      }

      & iframe {
        width: 100%;
      }
    }
  }

  &_closeicon {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    cursor: pointer;
  }
}

.tooltip1 {
  display: none;
}
.tooltip-on-hover:hover ~ .tooltip1 {
  display: block;
  color: red;
  font-size: 0.8rem;
}

.feed__attachment {
  // width: 280px;
  // height: 144px;
  max-width: 280px;
  max-height: 144px;
  opacity: 1;
  margin-left: 3rem;
  cursor: default;
}
.medicine_icon{
  max-width: 100px;
  max-height: 100px;
}