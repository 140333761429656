.Banner_size {
  width: 362px;
  height: 221px;
  opacity: 1;
  margin-left: 3rem;
  cursor: default;
}

h1 {
  display: flex;
  justify-content: space-between;
}

// .react-toggle {
//   touch-action: pan-x;
//   display: inline-block;
//   position: relative;
//   cursor: pointer;
//   background-color: transparent;
//   border: 0;
//   padding: 0;
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//   -webkit-tap-highlight-color: transparent;
// }

// .react-toggle-screenreader-only {
//   border: 0;
//   clip: rect(0 0 0 0);
//   height: 1px;
//   margin: -1px;
//   overflow: hidden;
//   padding: 0;
//   position: absolute;
//   width: 1px;
// }

// .react-toggle--disabled {
//   cursor: not-allowed;
//   opacity: 0.5;
//   -webkit-transition: opacity 0.25s;
//   transition: opacity 0.25s;
// }

// .react-toggle-track {
//   width: 50px;
//   height: 24px;
//   padding: 0;
//   border-radius: 30px;
//   background-color: #4d4d4d;
//   -webkit-transition: all 0.2s ease;
//   -moz-transition: all 0.2s ease;
//   transition: all 0.2s ease;
// }

// .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
//   background-color: #000000;
// }

// .react-toggle--checked .react-toggle-track {
//   background-color: #19ab27;
// }

// .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
//   background-color: #128d15;
// }

// .react-toggle-track-check {
//   position: absolute;
//   width: 14px;
//   height: 10px;
//   top: 0px;
//   bottom: 0px;
//   margin-top: auto;
//   margin-bottom: auto;
//   line-height: 0;
//   left: 8px;
//   opacity: 0;
//   -webkit-transition: opacity 0.25s ease;
//   -moz-transition: opacity 0.25s ease;
//   transition: opacity 0.25s ease;
// }

// .react-toggle--checked .react-toggle-track-check {
//   opacity: 1;
//   -webkit-transition: opacity 0.25s ease;
//   -moz-transition: opacity 0.25s ease;
//   transition: opacity 0.25s ease;
// }

// .react-toggle-track-x {
//   position: absolute;
//   width: 10px;
//   height: 10px;
//   top: 0px;
//   bottom: 0px;
//   margin-top: auto;
//   margin-bottom: auto;
//   line-height: 0;
//   right: 10px;
//   opacity: 1;
//   -webkit-transition: opacity 0.25s ease;
//   -moz-transition: opacity 0.25s ease;
//   transition: opacity 0.25s ease;
// }

// .react-toggle--checked .react-toggle-track-x {
//   opacity: 0;
// }

// .react-toggle-thumb {
//   transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
//   position: absolute;
//   top: 1px;
//   left: 1px;
//   width: 22px;
//   height: 22px;
//   border: 1px solid #4d4d4d;
//   border-radius: 50%;
//   background-color: #fafafa;
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
//   -webkit-transition: all 0.25s ease;
//   -moz-transition: all 0.25s ease;
//   // transition: all 0.25s ease;
// }

// .react-toggle--checked .react-toggle-thumb {
//   left: 27px;
//   border-color: #19ab27;
// }

// .react-toggle--focus .react-toggle-thumb {
//   -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
//   -moz-box-shadow: 0px 0px 3px 2px #0099e0;
//   box-shadow: 0px 0px 2px 3px #0099e0;
// }

// .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
//   -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
//   -moz-box-shadow: 0px 0px 5px 5px #0099e0;
//   box-shadow: 0px 0px 5px 5px #0099e0;
// }

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(20, 19, 19, 0.2) !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #bfd02e;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
