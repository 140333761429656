.Action {
  min-width: 6rem;
  border: 1px solid black;
  position: absolute;
  background: white;
  // right: 5rem;
  right: 3rem;
  top: 3rem;
  margin-top: -1rem;
  z-index: 10;
}

.Action-text {
  text-align: left;
  // padding-left: 1rem;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid gainsboro;
  margin-bottom: 0;
  cursor: pointer;
}
