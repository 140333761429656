.Widget-container {
  margin-top: 1rem;
  background-color: #f8f8f8;
  &__Widget {
    width: 100%;

    height: auto;
  }

  &__section-one {
    border: 1px dashed #d1d1d1;
    padding: 0.7rem;
    margin-bottom: 0.5rem;
    // text-align: center;
  }

  &__Image-Container {
    width: 100%;
    padding: 1rem;

    & .attachment {
      padding: 0;
    }
  }

  & .card__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #d1d1d1;
    padding-bottom: 0.7rem;
    margin-bottom: 0.7rem;

    // &__section {
    //   font-weight: bold;
    // }
    & p {
      margin-bottom: 0;
      font-weight: bold;
    }

    & img {
      cursor: pointer;
    }
  }
}

.Widget-container-disabled {
  margin-top: 1rem;
  // background-color: #f8f8f8;
  background-color: #f8f8f8;
  color: #d4d4d4;
  &__Widget {
    width: 100%;

    height: auto;
  }

  &__section-one-disabled {
    // border: 1px dashed #d1d1d1;
    padding: 0.7rem;
    margin-bottom: 0.5rem;
    border: 1px solid #e7e7e7;

    text-align: center;
  }

  &__Image-Container {
    width: 100%;
    padding: 1rem;

    & .attachment {
      padding: 0;
    }
  }

  & .card__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #d1d1d1;
    padding-bottom: 0.7rem;
    margin-bottom: 0.7rem;

    // &__section {
    //   font-weight: bold;
    // }
    & p {
      margin-bottom: 0;
      font-weight: bold;
    }

    & img {
      cursor: pointer;
    }
  }
}

// .newdesigntable {
//   table {
//     font-family: Arial, Helvetica, sans-serif;
//     border-collapse: collapse;
//     width: 100%;
//   }

//   table td,
//   table th {
//     border: 1px solid #e7e7e7;
//     padding: 8px;
//     text-align: center;
//   }

//   // table tr:nth-child(even) {
//   //   background-color: #f8f8f8;
//   // }

//   // table tr:hover {
//   //   background-color: #ddd;
//   // }

//   table th {
//     padding-top: 12px;
//     padding-bottom: 12px;
//     text-align: left;
//     background-color: #f8f8f8;
//     color: #d4d4d4;

//     & .arrow {
//       height: 1.6rem;
//       margin-left: 0.2rem;
//     }
//   }

//   .pointer {
//     cursor: pointer;
//   }

//   .pagination {
//     margin: auto !important;
//     padding: 1.5rem !important;
//     align-items: center !important;
//     justify-content: center !important;

//     & button {
//       margin-right: 1rem;
//       padding: 0.5rem;

//       :nth-last-child(1) {
//         margin-right: 0;
//       }
//     }

//     & span {
//       margin-left: 1rem;
//       margin-right: 1rem;

//       // &:not(:last-child) {
//       //   margin-left: 0rem;
//       // }
//     }
//   }
// }
