@import "styles/variables.scss";

.cms-content__container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.table {
  font-size: 0.8rem;
}
.content-container__actionBox {
  display: flex;
  justify-content: space-between;
}
.content-container__perPage {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.content-container__filterSearch {
  display: flex;
  column-gap: 0.5rem;
}

.content-container__pagination {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  .cursor-pointer {
    cursor: pointer;
  }
  #active-page {
    background-color: $light_grey_1;
  }
}
.centerButton{
    display: flex;
  justify-content: center;
  margin-right: 15%;
}

.custom-select {
  padding: 0.5rem 1rem;
  border: 1px solid $light_grey_1;
  border-radius: 0.4rem;
}
.nowrap{
    white-space: nowrap;
}
.text-center{
    text-align: center;
}

