.radio {
  margin-top: 1.5rem;

  &_container {
    display: flex;
    align-items: center;

    & > div:not(:first-child) {
      margin-left: 1rem;
    }

    &__title {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

  &__dropdown {
    margin-top: 1rem;
  }
}
